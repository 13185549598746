<script>
//import {required, email} from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import moment from "moment";

import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import NumberInputSpinner from "vue-number-input-spinner";

import Select2 from 'v-select2-component';
import "vue-multiselect/dist/vue-multiselect.min.css";
import ClienteService from "@/services/ClienteService";
const itemService = new ClienteService();

import ZonaService from "@/services/ZonaService";
const relationService = new ZonaService();

import HorarioService from "@/services/HorarioService";
const horarioService = new HorarioService();

import ArregloService from "@/services/ArregloService";
const arregloService = new ArregloService();


import PedidoService from "@/services/PedidoService";
const pedidoService = new PedidoService();


import InfoModel from "@/classes/cliente";
const infoModel = new InfoModel();

import PedidoArregloModel from "@/classes/pedido_arreglo";
const cartItem = new PedidoArregloModel();

import PedidoModel from "@/classes/pedido";
const pedidoModel = new PedidoModel();

import RelationModel from "@/classes/direccion";
const relationModel = new RelationModel();

import HelperJS from "@/functions/general";
const helper = new HelperJS();
/**
 * Checkout component
 */

 const nameSeccion = 'Pedido';
//const namePlural = nameSeccion+'s';
const routeParentName = 'pedidos';
export default {
  components: { Layout, PageHeader, 
    NumberInputSpinner,
    DatePicker,
    Select2},
  page: {
    title: nameSeccion,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: nameSeccion,
      value: '',
      actionCreate: true,
      info_page:{       
        routeParentName: null,
        routeBack: null,
        nameSeccion: null,
      },
      pedidoData: {},
      arreglo_value: null,
      cliente_value: null,
      objectData:{},
      formRelation: {},
      newDirection: false,
      zonas: [],
      horarios: [],
      arreglosArray: [],
      clientesArray: [],
      cart:{
        items: [],
        total: 0,
        subtotal: 0,
        total_items: 0,
        shipping: 0,
      },
      
      existClient:0,
      items: [
        {
          text: null,
        },
        {
          text: null,
          active: true,
        },
      ],
      method_payments:[
        {
          text: 'Tarjeta de crédito / débito',
          value: 0,
          icon: 'uil uil-card-atm'
        },
        {
          text: 'OXXO PAY',
          value: 1,
          icon: 'uil uil-store-alt'
        },
        {
          text: 'PayPal',
          value: 2,
          icon: 'uil uil-paypal-alt'
        },
        {
          text: 'Transferencia bancaria',
          value: 3,
          icon: 'mdi mdi-bank-transfer'
        },
        {
          text: 'Pago en destino / Efectivo',
          value: 4,
          icon: 'mdi mdi-home-currency-usd'
        }
      ],
      states_payments:[
        {
          text: 'Pendiente',
          value: 0,
          icon: 'mdi mdi-clock-alert-outline'
        },
        {
          text: 'Pagado',
          value: 1,
          icon: 'mdi mdi-check-circle-outline'
        },
        {
          text: 'Cancelado',
          value: 2,
          icon: 'mdi mdi-cancel'
        }
      ],
      submitted: false,
      validationErrors: {
        cliente_value:{
          required:false,
          show:false,
        },
        name: {
          required:false,
          show:false,
        },
        last_name: {
          required:false,
          show:false,
        },
        email:{
          required:false,
          show:false,
          email:false
        },
        arreglos:{
          required:true,
          show:false,
          minLenght: 1
        },
        date:{
          required:true,
          show:false,
        },
        send_time:{
          required:true,
          show:false,
        },
        method_payment:{
          required:true,
          show:false,
        },
        payment_status:{
          required:true,
          show:false,
        },
        address:{
          required:false,
          show:false,
        },
        form_address:{
          required:false,
          show:false,
        },

      },
    };
  },
  validations: {
    },
  middleware: "authentication",
  created(){

    this.initPage();
  },
  methods: {
    async initPage(){
        
        await this.aditionalServices();
        this.info_page.routeParentName = routeParentName;
        this.info_page.nameSeccion = nameSeccion;
        this.info_page.routeBack = '/'+routeParentName;
        let text_page = 'Agregar '
        
        this.pedidoData = JSON.parse(JSON.stringify(pedidoModel.fillable));
        this.objectData = JSON.parse(JSON.stringify(infoModel.fillable));
        if(this.$route.name===nameSeccion+'Edit'){          
          this.actionCreate = false;
          text_page = 'Editar';
          pedidoService.getById(this.$route.params.id).then(async response=>{  
            let data = response.data.object_data;

            data.arreglos.map(item=> item.name = item.arreglo.name);
            data.arreglos.map(item=> item.price_buy = item.price);
            data.arreglos.map(item=> item.total = item.price * item.quantity);
            data.arreglos.map(item=> item.image = item.arreglo.first_image);
            data.date = moment(data.date).format('YYYY-MM-DD');
            this.pedidoData =   JSON.parse(JSON.stringify(new PedidoModel(data)));
            this.cliente_value = this.pedidoData.cliente_id;
            this.existClient = 1;
            this.objectData =   JSON.parse(JSON.stringify(new InfoModel(data.cliente)));


            
          this.formRelation = JSON.parse(JSON.stringify(data.direccion))
          
          /*this.pedidoData.zona_id =   this.formRelation.zona_id;
          this.newDirection = false;*/
          
          this.updateDeliviery();
          

          /*itemService.getById(this.$route.params.id).then(async response=>{  
            let data = response.data.object_data;
            this.objectData =   JSON.parse(JSON.stringify(new InfoModel(data)));*/
          })
        }else{
          //this.objectData.direcciones.push(JSON.parse(JSON.stringify(relationModel.fillable)))
        }
        this.items[0].text= nameSeccion
        this.items[1].text= text_page+' '+nameSeccion;
      },
      
      async aditionalServices(){
        let response = await relationService.getList();
        response =  response.data.object_data;
        this.zonas = response;

        
        let responseTime = await horarioService.getList();
        responseTime =  responseTime.data.object_data;
        this.horarios = responseTime;

        response = await arregloService.getList();
        response =  response.data.object_data;
        response.map(item=> item.text = item.name);

        this.arreglosArray = response
        

        response = await itemService.getList();
        response =  response.data.object_data;
        response.map(item=> item.text = item.full_name);

        this.clientesArray = response
      },
      async changeCliente(cliente_value){
        itemService.getById(cliente_value).then(async response=>{  
            let data = response.data.object_data;
            this.objectData =   JSON.parse(JSON.stringify(new InfoModel(data)));
            this.formRelation = JSON.parse(JSON.stringify(new RelationModel(data.direcciones.find(item=> item.default===1))));
            this.updateDeliviery();
          })
      },
      actionUser(){        
        this.objectData = JSON.parse(JSON.stringify(infoModel.fillable));
        this.cliente_value = null;
      },
      async updateForm(){

          await this.createClient();
          
          let formData = JSON.parse(JSON.stringify(this.pedidoData));
          formData.date = helper.formatDate(formData.date, 'database');
         
          if(this.actionCreate){
            delete formData.id;
            await pedidoService.create(formData)
          }else{
            await pedidoService.update(formData)
          }
         this.successForm(this.info_page.routeBack);
      
      },


      async createClient(){
        let formData = JSON.parse(JSON.stringify(this.objectData));
        let response = {};
        if(this.newDirection){
          formData.direccionNueva = JSON.parse(JSON.stringify(this.formRelation))
        }
        let cliente_id = null;
        let direccion_id = null;
       
        if(this.existClient){

          cliente_id = this.cliente_value;
          if(this.newDirection){
            
           formData = JSON.parse(JSON.stringify(this.formRelation))
           formData.cliente_id = cliente_id;
           formData.default = 1;
           response = await itemService.createAddress(formData)
           direccion_id = response.data.object_data.id;
          }else{
            direccion_id = this.formRelation.id;
          }
         
        }else{          
          delete formData.id;
          formData.default = 1;
           response = await itemService.create(formData)
            cliente_id = response.data.object_data.id;
            direccion_id = response.data.relation_data.id;
        }
        this.pedidoData.direccion_id = direccion_id;
        this.pedidoData.cliente_id = cliente_id;
      },

      successForm(route){
        Swal.fire("¡Operación exitosa!", "Información guardada con éxito", "success").then(() => {
        this.$router.push({
              path: route,
            });
        });
    },
    cancelForm(){
      this.$router.push({
        path:'/'+routeParentName,
      });
    },
    addDirection(){
          this.formRelation = JSON.parse(JSON.stringify(relationModel.fillable))

          this.newDirection = !this.newDirection;
          
          this.updateDeliviery();
    },
    activeDirection(dataInfo){
          this.objectData.direcciones.map(item=> item.default = false);
          dataInfo.default = true;
          this.formRelation = JSON.parse(JSON.stringify(dataInfo))
          this.newDirection = false;

          
          this.updateDeliviery();

    },
    
    editDirection(dataInfo){
          this.formRelation = JSON.parse(JSON.stringify(dataInfo))

          this.newDirection = true;
    },
      
    customValidateForm(){
      let existClient = this.existClient;
      let newDirection = this.newDirection;
      if(existClient){
        this.validationErrors.cliente_value.required = true;
        this.validationErrors.name.required = false;
        this.validationErrors.last_name.required = false;
        this.validationErrors.email.required = false;
      }else{
        this.validationErrors.name.required = true;
        this.validationErrors.last_name.required = true;
        this.validationErrors.email.required = true;
        this.validationErrors.cliente_value.required = false;
      }
      console.log(newDirection,  Object.keys(this.formRelation).length===0)
      if(newDirection || Object.keys(this.formRelation).length===0){
        this.validationErrors.form_address.required = true;
        this.validationErrors.address.required = false;
      }else{
        this.validationErrors.form_address.required = false;
        this.validationErrors.address.required = true;
      }
      this.processValidation();
      return !this.checkHasErrors();

    },
    checkHasErrors(){
      let hasErrors = 0;
      Object.keys(this.validationErrors).map(item=>{
        if(this.validationErrors[item].show){
          hasErrors += 1;
        }
      })
      return hasErrors>0;
    },
    processValidation(){

        this.validationErrors.cliente_value.show = (this.validationErrors.cliente_value.required && !this.validateTypes(this.cliente_value, 'required'));      
        this.validationErrors.name.show = (this.validationErrors.name.required && !this.validateTypes(this.objectData.name, 'required'));
        this.validationErrors.last_name.show = (this.validationErrors.last_name.required && !this.validateTypes(this.objectData.last_name, 'required'));
        this.validationErrors.email.show = (this.validationErrors.email.required && !this.validateTypes(this.objectData.email, 'email'));
        this.validationErrors.address.show = (this.validationErrors.address.required && Object.keys(this.formRelation).length===0);
        
     
        if(this.validationErrors.form_address.required){
          let valueForm = this.formRelation;
          let resultForm = 0;
          resultForm+= Object.keys(this.formRelation).length===0?1:0;
          resultForm += !(this.validateTypes(valueForm.full_name, 'required'))?1:0;
          resultForm += !(this.validateTypes(valueForm.phone, 'required'))?1:0; 
          resultForm += !(this.validateTypes(valueForm.phone, 'required'))?1:0; 
          resultForm += !(this.validateTypes(valueForm.street, 'required'))?1:0; 
          resultForm += !(this.validateTypes(valueForm.number, 'required'))?1:0; 
          resultForm += !(this.validateTypes(valueForm.colony, 'required'))?1:0; 
          resultForm += !(this.validateTypes(valueForm.zona_id, 'required'))?1:0; 
          resultForm += !(this.validateTypes(valueForm.zipcode, 'required'))?1:0; 
          this.validationErrors.form_address.show = (resultForm > 0)?true:false;         
         
        }
        
        this.validationErrors.arreglos.show = (this.validationErrors.arreglos.required && !this.validateTypes(this.pedidoData.arreglos, 'minLenght')); 
        this.validationErrors.date.show = (this.validationErrors.date.required && !this.validateTypes(this.pedidoData.date, 'required'));
        this.validationErrors.send_time.show = (this.validationErrors.send_time.required && !this.validateTypes(this.pedidoData.send_time, 'required'));
        this.validationErrors.method_payment.show = (this.validationErrors.method_payment.required && !this.validateTypes(this.pedidoData.method_payment, 'required'));
        this.validationErrors.payment_status.show = (this.validationErrors.payment_status.required && !this.validateTypes(this.pedidoData.payment_status, 'required'));

      
    },
    validateTypes(value, type){
      return helper.validateTypes(value, type);

    },
      validateForm(){

        this.submitted = true;
        let responseValidate = this.customValidateForm();
        console.log('responseValidate', responseValidate);
        if(responseValidate){
          this.updateForm();
        }
        
        this.$v.$touch();
      },
      slugify(stringValue){

        return helper.convertToSlug(stringValue);
      },
      addCart(){
        
       
        

        //check if item exist in cart
        let exist = this.pedidoData.arreglos.find(item=> item.arreglo_id == this.arreglo_value);
          console.log('exist',exist);
        
        if(exist){
          Swal.fire("¡Advertencia!", "El arreglo ya se encuentra en el carrito", "success");
          
        }else{
          
          let info = this.arreglosArray.find(item=> item.id == this.arreglo_value);
          let item = JSON.parse(JSON.stringify(cartItem.fillable));
          item.arreglo_id = this.arreglo_value;
          item.quantity = 1;
          item.name = info.name;
          item.price = info.price;
          item.image = info.first_image;
          item.price_off = info.price_off;
          item.price_buy = (info.price_off)? info.price_off: info.price;
          item.total = item.quantity * item.price_buy;
          this.pedidoData.arreglos.push(item);
        }




        this.updateTotal();
        this.arreglo_value = null;
      },
      removeCart(idx)
      {
        //Remove item of pedidoData by index
        this.pedidoData.arreglos.splice(idx, 1);
        this.updateTotal();
      },
      updateCart(idx, quantity){
        //Update quantity of item by index
        console.log('cambio');
        this.pedidoData.arreglos[idx].quantity = quantity;
        this.pedidoData.arreglos[idx].total = quantity * this.pedidoData.arreglos[idx].price_buy;
        this.updateTotal();
      

      },
      updateDeliviery(){
        let cost_shipping = 0;
        let zona_id =  this.formRelation.zona_id;
        let zona = this.zonas.find(item=> item.id == zona_id);
        this.pedidoData.zona_id = zona_id;
        if(zona){
          cost_shipping = zona.price;
        }
        this.pedidoData.shipping = cost_shipping;
        
        this.updateTotal();
      },
      updateTotal(){
        let total = 0;
        let subtotal = 0;
        let total_items = 0;
        let shipping = this.pedidoData.shipping;
        this.pedidoData.arreglos.map(item=>{
          total_items += item.quantity;
          subtotal += item.total;
        })
        total = subtotal + shipping;
        this.pedidoData.total = total;
        this.pedidoData.subtotal = subtotal;
        this.pedidoData.total_items = total_items;
        this.pedidoData.shipping = shipping;
      },
     routeGo(textRoute){
      this.$router.push({
        path: textRoute,
      });
    },
    
    customFormatter(date) {
      return moment(date).format('YYYY-MM-DD');
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-7">
        <div class="row">
      <div class="col-lg-12">
        <div id="addproduct-accordion" class="custom-accordion">
          <div class="card">
          
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        01
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Información general</h5>
                    <p class="text-muted text-truncate mb-0">
                      Completa todos los datos
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            

            <b-collapse
              data-parent="#addproduct-accordion"
              id="accordion-1"
              visible
              accordion="my-accordion"
            >
              <div class="p-4 border-top">
                <form>
                  <div class="row">
                    <div class="col12">
                      <h5 class="font-size-14 mb-3">Usuario existente</h5>

                        <div class="row">
                          <div class="col-lg-3 col-sm-6">
                            <div data-toggle="collapse">
                              <label class="card-radio-label">
                                <input
                                  type="radio"
                                  name="user-action"
                                  id="user-action1"
                                  class="card-radio-input"
                                  :value="1"
                                  v-model="existClient"
                                  @input="actionUser(existClient)"
                                />

                                <span class="card-radio text-center text-truncate">
                                  <i class="uil uil-user d-block h2 mb-3"></i>
                                  Usuario existente
                                </span>
                              </label>
                            </div>
                          </div>

                          <div class="col-lg-3 col-sm-6">
                            <div>
                              <label class="card-radio-label">
                                <input
                                  type="radio"
                                  name="user-action"
                                  id="user-action2"
                                  class="card-radio-input"
                                  :value="0"
                                  v-model="existClient"
                                  @input="actionUser(existClient)"
                                />

                                <span class="card-radio text-center text-truncate">
                                  <i class="uil uil-user-plus d-block h2 mb-3"></i>
                                  Usuario nuevo
                                </span>
                              </label>
                            </div>
                          </div>

                        
                        </div>
                    </div>
                  </div>
                  <div class="row" v-if="existClient!=0">
                    <div class="col-12">
                        <label for="name">Buscar cliente</label>
                      <Select2  
                      @change="changeCliente(cliente_value)"
                      name="cliente_value"
                      :class="{
                        'is-invalid': submitted && validationErrors.cliente_value.show
                      }"
                       
                        class="form-control form-custom" v-model="cliente_value" :options="clientesArray"
                         :settings="{ settingOption: value, settingOption: value }"
                         /> 
                        <div v-if="submitted && validationErrors.cliente_value.show  && existClient!=0" class="invalid-feedback">
                          <span>Campo requerido</span>
                    </div>
                    </div>
                  
                  </div>
                 
                  <div class="row" v-if="existClient==0">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="name">Nombre</label>
                        <input id="name" name="name" v-model="objectData.name" type="text" class="form-control"
                          :class="{
                        'is-invalid': submitted && validationErrors.name.show
                          }"/>
                        <div v-if="submitted && validationErrors.name.show" class="invalid-feedback">
                          <span>Campo requerido</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="last_name">Apellidos</label>
                        <input id="last_name" name="last_name" v-model="objectData.last_name" type="text" class="form-control"
                          :class="{
                            'is-invalid': submitted && validationErrors.last_name.show
                          }"/>
                        <div v-if="submitted && validationErrors.last_name.show" class="invalid-feedback">
                          <span>Campo requerido</span>
                        </div>
                      </div>
                    </div>
                
                  </div>
                  <div class="row"  v-if="existClient==0">
                    <div class="col-md-6">
                  
                  
                      <div class="mb-3">
                        <label>Correo electrónico</label>
                          <input
                            v-model="objectData.email"
                            type="email"
                            name="email"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && validationErrors.email.show
                            }"
                            placeholder="Ingresar Email"/>
                          <div v-if="submitted && validationErrors.email.show" class="invalid-feedback">
                            <span v-if="validationErrors.email.show && !validationErrors.email.email">Añadir un correo eléctronico válido.</span>
                            <span v-if="validationErrors.email.email">Añadir un correo eléctronico válido.</span>
                          </div>
                      </div>
                    </div>
                    
                    <div class="col-md-6">
                      <div class="form-group mb-3">
                          <label>Teléfono</label>
                          <input v-model="objectData.phone" type="text" class="form-control" />
                      </div>
                       
                    </div>
                  </div>
                
                </form>
              </div>
            </b-collapse>
          </div>


          <div class="card">
            <a
              href="javascript: void(0);"
              class="collapsed text-dark"
              data-toggle="collapse"
              v-b-toggle.checkout-shippinginfo-collapse
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <i class="uil uil-truck text-primary h2"></i>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Dirección de envío</h5>
                    <p class="text-muted text-truncate mb-0">
                     
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse id="checkout-shippinginfo-collapse">
              <div class="p-4 border-top">
                <h5 class="font-size-14 mb-3">Información dirección </h5>
                <div class="row">

                  <div class="col-lg-4 col-sm-6" v-for="direccion, key in objectData.direcciones" :key="key">
                    <div class="card border rounded shipping-address" :class="direccion.default?'active':''">
                      <div class="card-body">

                      

                        <!--<a
                          class="float-end ms-1"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit"
                          @click="editDirection(direccion)"
                        >
                          <i class="uil uil-pen font-size-16"></i>
                        </a>-->
                        <a
                          href="#"
                          class="float-end ms-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Seleccionar default"
                          @click="activeDirection(direccion)"
                        >
                          <i class="mdi font-size-16" :class="direccion.default?' mdi-star':' mdi-star-outline'"></i>
                        </a>                 
                        <h5 class="font-size-14">{{ direccion.full_name }}</h5>
                        <p class="mb-1">
                          {{ direccion.street+' #'+ direccion.number+', '+direccion.colony+'. CP: '+direccion.zipcode+' '+direccion.zona_name}}

                        </p>
                        <span class="mb-0"><i class="uil uil-phone"></i> {{ direccion.phone }}</span>
                        <p class="mb-0">{{ direccion.message }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                    <div class="card  rounded  shipping-address">
                      <div class="card-body text-center pt-5">
                        <a
                         
                          class="float-center ms-1"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Agregar"
                          @click="addDirection()"
                        >
                       
                        <i class="uil uil-plus-circle font-size-24"></i>
                        <h5 class="font-size-14">Agregar dirección</h5>
                      </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                        <div v-if="submitted && validationErrors.address.show" class="invalid-feedback d-block">
                            <span >Es necesario agregar una dirección de entrega.</span>
                          </div> 
                      </div>
                </div>
                <div class="row" v-if="newDirection">
                  <form>
                  <div>
                    <div class="row">
                      <!--<div class="col-lg-4">
                        <div class="form-group mb-4">
                          <label for="billing-name">Nombre del lugar</label>
                          <input v-model="formRelation.name" type="text"  class="form-control" id="billing-name"/>
                        </div>
                      </div>-->
                      <div class="col-lg-4">
                        <div class="form-group mb-4">
                          <label for="billing-name">Nombre de quien recibe</label>
                          <input v-model="formRelation.full_name" type="text"  class="form-control" id="billing-name"
                          :class="{
                        'is-invalid': submitted && validationErrors.form_address.show
                          }"/>
                        </div>
                      </div>
                      
                      <div class="col-lg-4">
                        <div class="form-group mb-4">
                          <label for="billing-email-address">Correo electrónico</label>
                          <input v-model="formRelation.email" type="email"  class="form-control" id="billing-name"/>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group mb-4">
                          <label for="billing-phone">Teléfono</label>
                          <input v-model="formRelation.phone" type="text"  class="form-control" id="billing-name"
                          :class="{
                        'is-invalid': submitted && validationErrors.form_address.show
                          }"/>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-3">
                        <div class="form-group mb-4">
                          <label for="billing-name">Calle</label>
                          <input v-model="formRelation.street" type="text"  class="form-control" id="billing-name"
                          :class="{
                        'is-invalid': submitted && validationErrors.form_address.show
                          }"/>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group mb-4">
                          <label for="billing-name">Número Int / Ext</label>
                          <input v-model="formRelation.number" type="text"  class="form-control" id="billing-name"
                          :class="{
                        'is-invalid': submitted && validationErrors.form_address.show
                          }"/>
                        </div>
                      </div>
                      
                      <div class="col-lg-3">
                        <div class="form-group mb-4">
                          <label for="billing-email-address">Colonia</label>
                          <input v-model="formRelation.colony" type="text"  class="form-control" id="billing-name"
                          :class="{
                        'is-invalid': submitted && validationErrors.form_address.show
                          }"/>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group mb-4">
                          <label for="billing-phone">Código Postal</label>
                          <input v-model="formRelation.zipcode" type="text"  class="form-control" id="billing-name"
                          :class="{
                        'is-invalid': submitted && validationErrors.form_address.show
                          }"/>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-3">
                        <div class="form-group mb-4">
                          <label for="billing-name">Ciudad</label>
                        
                          <select
                            class="form-control custom-select"
                            title="Country"
                            v-model="formRelation.zona_id"
                            @change="updateDeliviery()"
                            :class="{
                              'is-invalid': submitted && validationErrors.form_address.show
                            }"
                          >
                            <option value="0">Seleccionar ciudad</option>
                            <option :key="idx" :value="zona.id" v-for="(zona, idx) in zonas">{{zona.name}}</option>
                                              
                          </select>
                        </div>
                      </div>
                     
                      <div class="col-lg-9">
                        <div class="form-group mb-4">
                          <label for="billing-address">Comentario</label>
                              <textarea
                              v-model="formRelation.comment"
                                class="form-control"
                                id="billing-address"
                                rows="3"
                              ></textarea>
                        </div>
                      </div>
                       
                    </div>
                  </div>
                </form>
                </div>
                <div class="row">
                  <div class="col-12">
                        <div v-if="submitted && validationErrors.form_address.show" class="invalid-feedback d-block">
                            <span >Es necesario completar el formulario dirección de entrega.</span>
                          </div> 
                      </div>
                </div>
              </div>
             
            </b-collapse>
          </div>
          <div class="card">
            <a
              href="javascript: void(0);"
              class="collapsed text-dark"
              data-toggle="collapse"
              v-b-toggle.checkout-paymentinfo-collapse
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <i class="uil uil-bill text-primary h2"></i>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Información del pedido</h5>
                    <p class="text-muted text-truncate mb-0">
                      Completa la información del pedido
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse id="checkout-paymentinfo-collapse">
              <div class="p-4 border-top">
                <div>
                  <label for="name">Buscar Arreglo</label>

                  <div class="row">
                    <div class="col-9">
                      
                      <Select2  
                       
                        class="form-control form-custom" v-model="arreglo_value" :options="arreglosArray"
                         :settings="{ settingOption: value, settingOption: value }"
                         /> 
                    </div>
                    <div class="col-3">
                      <button @click="addCart()" class="btn btn-info"> <i class="mdi mdi-cart  "></i> Agregar</button>
                    </div>
                  </div>
                 
                  <div class="row">
                    <div class="col-12">
                      

                      <div class="card border shadow-none mt-4" v-for="(item, idx) in pedidoData.arreglos" :key="idx">
                        <div class="card-body">
                          <div class="media border-bottom pb-3">
                            <div class="me-4">
                              <img
                                :src="item.image"
                                alt
                                class="avatar-lg"
                              />
                            </div>
                            <div class="media-body align-self-center overflow-hidden">
                              <div>
                                <h5 class="text-truncate font-size-16">
                                    <label>{{ item.name }}</label>
                                </h5>
                                
                            <div class="row">
                              <div class="col-md-4">
                                <div class="mt-3">
                                  <div v-if="item.price_off"> 
                                    <p class="text-muted mb-2">Precio</p>
                                    <h5 class="font-size-16 text-removed">${{item.price}}</h5>
                                    <h5 class="font-size-16 price-off">${{item.price_off}}</h5>
                                  </div>
                                  <div v-else> 
                                    <p class="text-muted mb-2">Precio</p>
                                    <h5 class="font-size-16">${{item.price}}</h5>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="mt-3">
                                  <p class="text-muted mb-2">Cantidad</p>
                                  <div style="width: 120px" class="product-cart-touchspin">
                                    <div class="input-group">
                                      <NumberInputSpinner
                                        :min="1"
                                        :max="100"
                                        :mouseDownSpeed="500"
                                        :integerOnly="true"
                                        v-model.number="item.quantity"
                                        :value="item.quantity"
                                        @input="updateCart(idx, item.quantity)"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="mt-3">
                                  <p class="text-muted mb-2">Total</p>
                                  <h5 class="font-size-16">${{item.total}}</h5>
                                </div>
                              </div>
                            </div>
                              </div>
                            </div>
                            <div class="ms-2">
                              <ul class="list-inline mb-0 font-size-16">
                                <li class="list-inline-item" v-b-tooltip.hover title="Remove">
                                  <a @click="removeCart(idx)" class="text-muted px-2">
                                    <i class="uil uil-trash-alt"></i>
                                  </a>
                                </li>                 
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                  <div class="row">
                    <div class="col-12">
                        <div v-if="submitted && validationErrors.arreglos.show" class="invalid-feedback d-block">
                            <span >Es necesario agregar un arreglo.</span>
                          </div> 
                      </div>
                  </div>

                <div class="row  mt-3">
                  <div class="col-lg-4 col-sm-6">
                   
                    <h5 class="font-size-14">Fecha de entrega</h5>
                        <date-picker
                          v-model="pedidoData.date"
                          append-to-body
                          format="YYYY-MM-DD"
                          value-type="YYYY-MM-DD"
                          :class="{
                            'is-invalid': submitted && validationErrors.date.show
                          }"
                          lang="es"
                        ></date-picker>
                        <div v-if="submitted && validationErrors.date.show" class="invalid-feedback">
                          <span>Campo requerido</span>
                        </div>

                  </div>
                  <div class="col-lg-4 col-sm-6">
                    <div class="form-group mb-4">
                      <h5 class="font-size-14">Horario de entrega</h5>
                        <select class="form-control custom-select"
                            title="Country"
                            v-model="pedidoData.send_time"
                          :class="{
                            'is-invalid': submitted && validationErrors.send_time.show
                          }"
                          >
                            <option :value="null">Seleccionar</option>
                            <option :key="idx" :value="horario.id" v-for="(horario, idx) in horarios">{{horario.text}}</option>
                                              
                          </select>
                          <div v-if="submitted && validationErrors.send_time.show" class="invalid-feedback">
                            <span>Campo requerido</span>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-8">
                        <div class="form-group mb-4">
                          <label for="billing-address">Dedicatoria / Mensaje</label>
                              <textarea
                              v-model="pedidoData.message"
                                class="form-control"
                                id="message"
                                rows="3"
                              ></textarea>
                        </div>
                      </div>
                </div>


                  <h5 class="font-size-14 mb-3 mt-3">Método de pago</h5>

                  <div class="row">
                    
                    <div class="col-md-3 col-sm-6" v-for="(itemMedthod, idx) in method_payments" :key="idx">
                      <div data-toggle="collapse">
                        <label class="card-radio-label">
                          <input
                            type="radio"
                            name="pay-method"
                            id="pay-methodoption1"
                            class="card-radio-input"
                            v-model="pedidoData.method_payment"
                            :value="itemMedthod.value"
                          />

                          <span class="card-radio text-center text-truncate">
                            <i class=" d-block h2 mb-3" :class="itemMedthod.icon"></i>
                            {{ itemMedthod.text }}
                          </span>
                        </label>
                      </div>
                    </div>

                    <div class="col-12">
                      <div v-if="submitted && validationErrors.method_payment.show" class="invalid-feedback">
                        <span>Campo requerido</span>
                      </div>
                    </div>
                  </div>
                  
                  <h5 class="font-size-14 mb-3 mt-3">Estado de pago</h5>

                  <div class="row">
            
                    <div class="col-lg-3 col-sm-6" v-for="(itemState, idx) in states_payments" :key="idx">
                      <div data-toggle="collapse">
                        <label class="card-radio-label">
                          <input
                            type="radio"
                            name="pay-state"
                            id="pay-stateoption1"
                            class="card-radio-input"
                            v-model="pedidoData.payment_status"
                            :value="itemState.value"
                          />

                          <span class="card-radio text-center text-truncate">
                            <i class=" d-block h2 mb-3" :class="itemState.icon"></i>
                            {{ itemState.text }}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div v-if="submitted && validationErrors.payment_status.show" class="invalid-feedback">
                        <span>Campo requerido</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col text-end ms-1">
        <a  class="btn btn-danger"  @click.prevent="cancelForm()">
          <i class="uil uil-times me-1"></i> Cancelar
        </a>
        <a  class="btn btn-success ms-1"  @click.prevent="validateForm()">
          <i class="uil uil-file-alt me-1" ></i> Guardar cambios
        </a>
      </div>
      <!-- end col -->
    </div>
        <!-- end row-->
      </div>
      <div class="col-md-5">
        <div class="card checkout-order-summary">
          <div class="card-body">
            <div class="p-3 bg-light mb-4">
              <h5 class="font-size-16 mb-0">
                Resumen del pedido
               
              </h5>
            </div>
            <div class="table-responsive">
              <table class="table table-centered mb-0 table-nowrap">
                <thead>
                  <tr>
                    <th class="border-top-0" style="width: 110px" scope="col">
                      Imagen
                    </th>
                    <th class="border-top-0" scope="col">Arreglo</th>
                    <th class="border-top-0" scope="col">Precio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr  v-for="(item, idx) in pedidoData.arreglos" :key="idx">
                    <th scope="row">
                      <img
                        :src="item.image"
                        alt="product-img"
                        title="product-img"
                        class="avatar-md"
                      />
                    </th>
                    <td>
                      <h5 class="font-size-14 text-truncate">
                        <router-link
                          to="/ecommerce/product-detail/1"
                          class="text-dark"
                          >{{ item.name }}</router-link
                        >
                      </h5>
                      <p class="text-muted mb-0">$ {{item.price_buy}} x {{item.quantity}}</p>
                    </td>
                    <td>$ {{item.total}}</td>
                  </tr>
                 
                  <tr>
                    <td colspan="2">
                      <h5 class="font-size-14 m-0">Subtotal:</h5>
                    </td>
                    <td>$ {{ pedidoData.subtotal }}</td>
                  </tr>
                  <!--<tr>
                    <td colspan="2">
                      <h5 class="font-size-14 m-0">Discount :</h5>
                    </td>
                    <td>- $ 78</td>
                  </tr>-->

                  <tr>
                    <td colspan="2">
                      <h5 class="font-size-14 m-0">Gastos de envío:</h5>
                    </td>
                    <td>$ {{pedidoData.shipping}}</td>
                  </tr>
                 

                  <tr class="bg-light">
                    <td colspan="2">
                      <h5 class="font-size-14 m-0">Total:</h5>
                    </td>
                    <td>$ {{pedidoData.total}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
